<template>
  <div class="new-component">
    <h1>A New Component</h1>
    <p>This is a text inside the NewComponent.</p>
 </div>
</template>

<script>
export default {
  name: 'NewComponent',
}
</script>