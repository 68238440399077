
<template>
  <div class="home">
    <div class="hero is-light is-fullheight pt-6 ">
      <div class="tile">
        <div class="columns is-centered is-vcentered ">
          <div class="column is-10 is-vcentered">
              <div class="tile is-ancestor pt-6 ">
                <div class="columns is-centered is-multiline ">
                <div class="tile is-parent is-vertical is-6">
                  <div class="tile is-child pt-6">

                    <div class="content heropadding">
                    <p class="title is-1 has-text-weight-bold">
                        Nous developpons des outils pour les professionels de la rénovation énergétique Genevois.
                    </p>

                      <p class="text is-size-4 mb-4 has-text-weight-light">
                        Le challenge est grand, nous vous aidons à vous concentrer sur l'essentiel.
                      </p>

                    <div class="field has-addons">

                      <p class="control">
                        <a class="button is-link" href="mailto:contact@elona-construction.ch">
                          Lancez-vous!
                        </a>
                      </p>
                    </div>
                  </div>

                </div>
                </div>
                

                <div class="tile is-parent is-6">
                  <div class="tile is-child pt-6">
                <div class="container is-fluid heropadding">
                  <img src="@/assets/images/undraw_data_processing_yrrv.svg" alt class="p-0" />
                </div>
                </div>
                </div>

              </div>
              </div>
          </div>
        </div> 
      </div>
    </div>

    <section class="hero pb-5">
      <div class="columns is-centered mb-6">
        <div class="column is-11">
      
          <div class="hero-body has-text-centered mb-6">
            <p class="title is-2 has-text-primary has-text-centered">
              Pourquoi utiliser les outils E-cco?
            </p>
            <p class="text-muted">
              Le secteur de la rénovation à Genève est nouveau et il manque d'outil spécifiques pour mener à bien sa mission. <br>
              E-cco est la première startup Genevoise à mettre ses compétences digitales à leur service.
            </p> 
          </div>
      
      
          <div class="tile is-ancestor px-5">
            <div class="columns is-centered is-multiline ">

            <div class="tile is-parent is-3 ">
              <article class="tile is-child ">
                  <div class="column has-text-centered p-3">
                    <span class="icon has-text-dark">
                    <font-awesome-icon icon="fa-solid fa-globe" size="6x" fade style="--fa-animation-duration: 15s;"/>
                    </span>
                </div>
                  <p class="title is-4 nobreak">Une vision globale et complète</p>
                  <div class="content">
                  <p class='has-text-weight-light'>
                    Chaque projet de transition énergétique est complexe et requiert une vision d'ensemble. Le Datafetcher collecte toutes les données dont vous avez besoin.
                  </p>
                </div>
              </article>
            </div>
            <div class="tile is-parent is-3">
              <article class="tile is-child ">
                <div class="column has-text-centered p-3 height:3em">
                  <span class="icon has-text-dark">
                    <font-awesome-icon icon="fa-solid fa-gauge-high" size="6x" fade style="--fa-animation-duration: 15s;"/>
                  </span>                
                  </div>
                <p class="title is-4 nobreak">Un service au top de la réactivité</p>
                <div class="content">
                  <p class='has-text-weight-light'>
                    Avant chaque mission vient le contact client. Suprenez et rassurez vos prospect en démontrant votre réactivité face à son cas spécifique.
                  </p >
                </div>
              </article>
            </div>
            

            <div class="tile is-parent is-3">
              <article class="tile is-child ">
                <div class="column has-text-centered p-3 height:3em">
                  <span class="icon has-text-dark">
                    <font-awesome-icon icon="fa-solid fa-piggy-bank" size="6x" fade style="--fa-animation-duration: 15s;"/>
                  </span>                
                  </div>
                <p class="title is-4 nobreak">Des économies de temps et d'argent</p>
                <div class="content">
                  <p class='has-text-weight-light'>
                    En automatisant le processus de collecte de données, nous vous aidons à occuper chaque heure facturée à votre spécialité en réduisant le temps de préparation.
                  </p>
                </div>
              </article>
            </div>

          </div>   
        </div>
            </div>
          </div>

    </section>




    <section class="hero is-halfheight is-light py-5 my-5">
      <div class="columns is-centered">
      <div class="column is-10 "> 
        <div class="container mb-6">
            <p class="title is-2 has-text-dark has-text-centered p-6 has-text-weight-bold">
              Une fonctionnalité pour chacun de vos besoins
              <!-- - Créez un ensemble de données par service
              - Organisez, supprimez et ajoutez des données pour chaque ensemble
              - Sauvegardez vos ensembles de données
              - Lancez les simulations pour mieux apréhender vos clients -->
            </p>
        </div>
        <div class="columns">
        <!-- <div class="column">
          <div class="card">
            <div class="column has-text-centered ">
                <span class="icon has-text-dark mt-6">
                  <font-awesome-icon icon="fa-solid fa-boxes-packing" size="3x" fade style="--fa-animation-duration: 15s;"/>
                </span>
            </div>
            <div class="card-content">
              <div class="content ">
                <p class="is-size-5	has-text-dark has-text-centered">
                  Créez autant de pack de données que vous avez de service.
                </p>
                <br>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="column has-text-centered ">
                <span class="icon has-text-dark mt-6">
                  <font-awesome-icon icon="fa-solid fa-globe" size="3x" fade style="--fa-animation-duration: 15s;"/>
                </span>
            </div>
            <div class="card-content">
              <div class="content ">
                <p class="is-size-5	has-text-dark has-text-centered">
                  Ajoutez et supprimez les données selon vos besoins.
                </p>
                <br>
              </div>
            </div>
          </div>
        </div>
        
        <div class="column">
          <div class="card">
            <div class="column has-text-centered ">
                <span class="icon has-text-dark mt-6">
                  <font-awesome-icon icon="fa-solid fa-globe" size="3x" fade style="--fa-animation-duration: 15s;"/>
                </span>
            </div>
            <div class="card-content">
              <div class="content ">
                <p class="is-size-5	has-text-dark has-text-centered">
                  Lancez des simulations pour chaque bâtiment.
                </p>
                <br>
              </div>
            </div>
          </div>
        </div> -->


</div>
      <div class="tile is-ancestor columns is-vcentered">
        
        <div class="column is-half has-text-centered">
          <img src="@/assets/images/Screenshot_datafetcher.png" alt class="p-0" />
       </div>
        <div class="tile is-4 is-vertical is-half ml-6">
          <p class="title is-6">
            DATA FETCHER
          </p>
          <p class="title is-4">
                Des ensembles de données
              <b>personalisables</b> &
              <b>duplicables</b> avec notre
              <b>Data Fetcher</b>
          </p>
                      <p class="text-muted font-size-15 mb-4">
              Nous avons collecté des millions de point de données sur le territoire Genevois pour que vous n'ayez pas à copier coller l'information sur chaque site officiel.
            </p>
          <i class="fa fa-box"></i>
          <!-- <font-awesome-icon icon="fa-regular fa-box-check" /> -->
          <p class="text-muted font-size-15 mb-4"> Selection des variables désirées parmis les sources SITG, geoadmin, SAP, ... </p>

          <i class="fa fa-clock"></i>
          <!-- <font-awesome-icon icon="fa-regular fa-box-check" /> -->
          <p class="text-muted font-size-15 mb-4"> Création de profils réutilisables pour une selection personalisé en fonction de chacun de vos services. </p>
        
          <div class="container p-5">
          <a class="button is-primary" href="mailto:contact@elona-construction.ch">Lancez-vous!</a>
          </div>
        </div>
      </div>
      </div>
      </div>
    </section>



    <section class="hero is-halfheight py-5 my-5">
      <div class="columns is-centered">
        <div class="column is-10 "> 
            <div class="column">
              <div class="card">
                <div class="column has-text-centered ">
                    <span class="icon has-text-dark mt-6">
                      <font-awesome-icon icon="fa-solid fa-globe" size="3x" fade style="--fa-animation-duration: 15s;"/>
                    </span>
                </div>
                <div class="card-content">
                  <div class="content ">
                    <p class="is-size-5	has-text-dark has-text-centered">
                      Données Physiques
                    </p>
                    <br>
                  </div>
                </div>
              </div>
            </div> 
            <div class="column">
              <div class="card">
                <div class="column has-text-centered ">
                    <span class="icon has-text-dark mt-6">
                      <font-awesome-icon icon="fa-solid fa-globe" size="3x" fade style="--fa-animation-duration: 15s;"/>
                    </span>
                </div>
                <div class="card-content">
                  <div class="content ">
                    <p class="is-size-5	has-text-dark has-text-centered">
                      Données administratives
                    </p>
                    <br>
                  </div>
                </div>
              </div>
            </div> 
            
        </div>
      </div>
    </section>

    <section class="hero is-halfheight py-5 my- has-background-light">
      <div class="columns is-centered">
        <div class="column is-10"> 
          <div class="tile is-ancestor columns is-vcentered">
                <div class="tile is-vertical is-half ml-6">
                   <p class="title is-4 nobreak">Faites notre connaissance</p>
                    <p class='has-text-weight-light'>
                      Une analyste de données, un ingénieur en efficience énergétique, un developpeur web, et une envie de faire bouger les choses en soutenant ceux qui sont aux premières lignes.
                    </p>
                    <div class="container my-6 is-align-items-flex-start">
                      <a class="button is-primary" href="mailto:contact@elona-construction.ch">Contactez Neigeline pour une présentation</a>
                    </div>
                </div>
          <div class="column is-half has-text-centered p-3">
              <img src="@/assets/image_team.png" alt class="" />
          </div>
      </div>
    
        </div>
      </div>
    </section>
  
 





  </div>

</template>
<script>




export default {
  name: 'Home',
  mounted(){
    document.title='Accueil | Elona Construction'
  },


}
</script>

<style scoped>
.centeredtile {
  align-items: center;
}

.nobreak{
  word-break: keep-all;
}

@media only screen and (max-width: 767px) {
.heropadding{ 
  padding: 2rem;
}

}


</style>