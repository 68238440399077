

<template>

<!-- <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Mulish:wght@200&family=Roboto:wght@700;900&display=swap" rel="stylesheet"> -->
    <div class="cities">
            <div class="hero has-background-primary-light is-light is-fullheight p-6 ">
      <div class="tile">
        <div class="columns is-centered is-vcentered ">
          <div class="column is-10 is-vcentered">
              <div class="tile is-ancestor pt-6 ">
                <div class="columns is-centered is-multiline ">
                <div class="tile is-parent is-vertical is-6">
                  <div class="tile is-child pt-6">

                    <div class="content heropadding">
                    <p class="title is-2">
                        Aidez votre ville à s'envoler vers la transition énergétique en gardant les pieds bien par terre
                    </p>


                    <div class="field has-addons">

                      <p class="control">
                        <a class="button is-primary" href="mailto:contact@elona-construction.ch">
                          Demandez plus d'information!
                        </a>
                      </p>
                    </div>
                  </div>

                </div>
                </div>
                

                <div class="tile is-parent is-6">
                  <div class="tile is-child">
                    <div class="container is-fluid heropadding has-text-centered ">
                    <div class="container ">
                        <figure class="image is-128*128 is-inline-block">
                            <img src="@/assets/images/undraw_city.svg" style = "max-height: 25em" alt class="p-0" />
                        </figure>
                        </div>
                    </div>
                </div>
                </div>

              </div>
              </div>
          </div>
        </div> 
      </div>
    </div>

    <section class="hero is-medium pb-5">
      <div class="columns is-centered">
        <div class="column is-7">
      
          <div class="hero-body has-text-centered">
            <div class="content">
            <p class="title is-3 has-text-primary has-text-centered">
              Gagnez une meilleur compréhension de la performance énergétique globale de votre ville. <br> Mettez en place les meilleurs plans d'action possibles.
            </p>
            <div class="containter px-6">
                <p class="text-muted has-text-centered">
                Nous aidons les villes à mettre en place le support digital pour leur permettre de comprendre, planifier, communiquer, 
                mais surtout améliorer leur potentiel d'amélioration de l'éfficacité énergétique.
                <br>
                Tout cela de manière globale et intégrée.
                </p> 
            </div>
            </div>
          </div>
        </div>
        </div>

    </section>







    <section class="hero is-halfheight has-background-warning-light py-5 my-5">
      <div class="columns is-centered p-5">
        <div class="column is-10 my-6"> 
            <div class="tile is-ancestor columns is-vcentered">
                <div class="tile is-vertical is-half ml-6">
                    <p class="title is-1">
                        Parc bâti
                    </p>

                    <p class="text-muted font-size-15 mb-4">
                        Quelles est la répartition énergétique des bâtiments de votre ville? <br>
                        Qu'est-ce qui cause les classes moins performantes?
                    </p>

                    <ul>
                        <li>
                            Identifiez les potentiels d'amélioration
                        </li>
                        <li>
                            Comparez et intéragissez avec les autres villes afin d'échanger les meilleurs procédés
                        </li>
                        <li>
                            Partagez un tableau de bord public avec vos citoyens sur les indicateurs généraux
                        </li>
                    </ul>

                <div class="container p-5">
                <a class="button is-primary" href="mailto:contact@elona-construction.ch">Lancez-vous!</a>
                </div>
                </div>
                <div class="column is-half has-text-centered ">
                    <div class="container p-3"> 
                <img src="@/assets/images/undraw_data_trends.svg" alt class="p-6" />
                </div>
                </div>

            </div>
        </div>
      </div>
    </section>

    <section class="hero is-halfheight  py-5 my-5">
      <div class="columns is-centered">
        <div class="column is-10 my-6"> 
            <div class="tile is-ancestor columns is-vcentered">
                
                <div class="column is-half has-text-centered ">
                    <div class="container p-3"> 
                <img src="@/assets/images/undraw_tourist_map.svg" alt class="p-6" />
                </div>
            </div>
                <div class="tile is-vertical is-half p-5">
                    <p class="title is-1">
                        Urbanisme et territoire
                    </p>
                    <p class="text-muted font-size-15 mb-4">
                        Gagnez une image globale des potentiels qu'offrent le territoire et découvrez de nouvelles opportunités
                    </p>

                    <ul>
                        <li>
                            Mettez en avant les solutions innovantes et durables en matière d'énergie.
                        </li>
                        <li>
                            Mettez en avant les solutions durables en terme de mobilité
                        </li>
                        <li>
                            Mettez en avant les entreprises, les personnes de contact et les évenements locaux
                        </li>
                    </ul>

                <div class="container p-5">
                <a class="button is-primary" href="mailto:contact@elona-construction.ch">Lancez-vous!</a>
                </div>
                </div>
            </div>
        </div>
      </div>
    </section>

    <section class="hero pb-5 has-background-warning-light">
      <div class="columns is-centered">
        <div class="column is-11">
      
          <div class="hero-body has-text-centered">
            <p class="title is-2 has-text-primary has-text-centered">
              Nos services
            </p>
            <div class="containter px-6">
                <p class="text-muted has-text-centered">
                Notre eventail de prestation assure un accompagnement complet et innovant pour aider les villes à atteindre concrêtment leurs objectifs.
                </p> 
            </div>
          </div>
          
            <div class="tile is-ancestor p-3">
            <div class="columns is-centered is-multiline ">

            <div class="tile is-parent is-3 ">
              <article class="tile is-child has-text-centered">
                <figure class="image is-28*28 is-inline-block p-5">
                    <img src="@/assets/images/audit.png" style = "max-height: 25em" alt class="p-0" />
                </figure>
                  <p class="title is-5 nobreak mx-5">Audit d'un expert certifié par la confédération</p>
                  <div class="content">

                </div>
              </article>
            </div>
                        <div class="tile is-parent is-3 ">
              <article class="tile is-child has-text-centered">
                <figure class="image is-28*28 is-inline-block p-5">
                    <img src="@/assets/images/data_engineering.png" style = "max-height: 25em" alt class="p-0" />
                </figure>
                  <p class="title is-5 nobreak mx-5">Ingénierie des données publiques et/ou communales</p>
                  <div class="content">

                </div>
              </article>
            </div>
                        <div class="tile is-parent is-3 ">
              <article class="tile is-child has-text-centered">
                <figure class="image is-28*28 is-inline-block p-5">
                    <img src="@/assets/images/report.png" style = "max-height: 25em" alt class="p-0" />
                </figure>
                  <p class="title is-5 nobreak mx-5">Création d'un rapport de conseil pdf & digital</p>
                  <div class="content">

                </div>
              </article>
            </div>
            <div class="tile is-parent is-3 ">
              <article class="tile is-child has-text-centered">
                <figure class="image is-28*28 is-inline-block p-5">
                    <img src="@/assets/images/dashboard.png" style = "max-height: 25em" alt class="p-0" />
                </figure>
                  <p class="title is-5 nobreak mx-5">Mise en ligne d'un dashboard public</p>
                  <div class="content">

                </div>
              </article>
            </div>


          </div>   
            </div>
        </div>
        </div>

    </section>


        <div class="columns is-centered">
        <div class="column is-6 p-4">
            <div v-if="messageOK" class="container">
                <div class="content pt-6">
                    <p class="title has-text-black has-text-centered"> Nous avons bien reçu votre message!</p>
                </div>
            </div>

            <div v-else>
            <div class="container p-6">
                <div class="card p-5 has-background-primary-light">
                <div class="hero-body has-text-centered">
                <p class="title has-text-black">
                    Contactez-nous
                </p>
                <p class="container">
                    Nous ne sommes qu'au début de l'aventure. Soyez-les premiers à prendre de l'avance & influencez le développement de nos futurs outils.
                </p>
                </div>
                <div class='container is-centered'>
                
                                <div class="field-body">
                                <div class="field">
                                    <label class="label">Nom <span class="has-text-danger has-text-weight-normal">*</span></label>

                                    <p class="control is-expanded">
                                    <input class="input" type="name" placeholder="Favre" name="nom" v-model="nom">
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-user"></i>
                                    </span>
                                    </p>
                                </div>
                                <div class="field">
                                    <label class="label"> Prénom <span class="has-text-danger has-text-weight-normal">*</span></label>

                                    <p class="control is-expanded">
                                    <input class="input" type="name" placeholder="Elona" name="prenom" v-model="prenom">

                                    </p>
                                </div>
                                </div>

                                <div class="field-body">
                                    <div class="field">
                                    <label class="label">Email <span class="has-text-danger has-text-weight-normal">*</span></label>
    
                                    <p class="control is-expanded has-icons-left">
                                        <input class="input" type="email" placeholder="elona@elona-construction.ch"  v-model="email" name="email">
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-envelope"></i>
                                    </span>
                                        <span class="icon is-small is-left">
                                        <i class="fas fa-user"></i>
                                        </span>
                                    </p>
                                    </div>
                                    <div class="field">
                                    <label class="label">Téléphone <span class="has-text-danger has-text-weight-normal">*</span></label>
    
                                    <p class="control is-expanded has-icons-left">
                                        <input class="input" type="telephone" placeholder="(00)"  v-model="telephone" name="telephone">
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-phone"></i>
                                    </span>
                                    </p>
                                    </div>

                                </div>
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">Décrivez en quelques mots vos besoins <span class="has-text-danger has-text-weight-normal">*</span></label>
                                    <div class="control">
                                        <textarea class="textarea" placeholder="Voici ma requête: ..." v-model="besoins" maxlength="900" ></textarea>
                                    </div>
                                </div>
                            </div>


                <div class="field is-horizontal is-align-content-start ">
                    <div class="field-body">
                    <div class="field">
                        <div class="control pt-2">
                        <button class="button is-primary" @click="submitMessage">
                            Send message
                        </button>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
            </div>
        </div>

        </div>


    </div>
</template>


<style scoped>
ul {
  display: table;
  margin: 0 auto;
}

ul {
  /* // list-style-image: '@/assets/images/check.png'; */
  list-style-type: '✅';
  padding-inline-start: 2ch;
  margin: 0 auto;
  display: table;
  
}

li {
   padding-inline-start: 3ch;
    margin: 15px auto;
}
</style>


<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'FormulaireDevis',
    components: {

    },
    data() {
      return{
        adresse:'',
        nom:'',
        prenom:'',
        email:'',
        telephone: '',
        besoins:'',
        messageOK:false,
        errorsForm:[],
        errors:[],
    }
      
    },
    mounted() {
    },
    methods: {
      submitMessage(){
        this.errors = []
            if (this.nom === '') {
                this.errors.push('Veuillez remplir le champ "Nom"')
            }
            if (this.prenom === '') {
                this.errors.push('Veuillez remplir le champ "Prénom"')
            }
            if (this.email === '') {
                this.errors.push('Veuillez remplir le champ "Email"')
            }
            if (this.telephone === '') {
                this.errors.push('Veuillez remplir le champ "telephone"')
            }
            if (this.besoins.length > 900) {
                this.errors.push('Le champ "besoins" est trop long')
            }

            if (!this.errors.length){
              this.sendData()
            }

      },
      async sendData(){
        this.$store.commit('setIsLoading', true)

        const data = {
          'nom':this.nom,
          'prenom':this.prenom,
          'email':this.email,
          'telephone':this.telephone,
          'besoins':this.besoins,
            }
        console.log('this is the data to be send: ',data)

        await axios
        .post('/api/v1/formulaireContact/', data)
        .then(response => {

        console.log("RESPONSE SERVER: ",response.data)
        this.messageOK = true

      })
      .catch(error => {
          this.errorsForm.push('Un problème est survenu. Veuillez réessayer')
          console.log(error)
      })
      this.$store.commit('setIsLoading', false)
      },

        

    },

}
</script>

